@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

html,
body,
#root {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif,
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.image-gallery-right-nav:before {
  text-shadow: none;
}

button.image-gallery-right-nav:hover::before {
  color: #84d1e0;
}

button.image-gallery-left-nav:before {
  text-shadow: none;
}

button.image-gallery-left-nav:hover::before {
  color: #84d1e0;
}

.Toastify__toast--info {
  background: #4b963e !important;
  color: white
}

.Toastify__toast--success {
  background: #4b963e !important;
  color: white
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

.Toastify__toast--error {
  background-color: #bc091b;
  color: white
}
